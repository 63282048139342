<div>
    <div class="container" class="overlay" *ngIf="loading">

        <div class="spinner-wrapper">
            <div class="row">
                <div id="loader">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="loading"></div>
                </div>
            </div>
        </div>
    </div>
    <section #host class="hero show-hero is-black has-hero-image is-thin ">
        <div class="hero-body overlay-hover">
            <div class="container">
                <div class="columns">

                    <div class="column description-section">
                        <div class="hero-content">
                            <h1 class="hero-title has-margin-bottom-6">BIG FACTS NO CAP </h1>
                            <div class="hero-subtitle ctrs-block is-richtext">
                                <h5>“It's a podcast, what else you got going on?” <span class="nobr">
                                        -listener</span> </h5>
                                <h5>“It’s fun to play along, but I can never anticipate where their minds end up going.
                                    The solutions they come up with are mindboggling. 3x3 stars” <span class="nobr">
                                        -Erno Rubik</span></h5>
                                <p>Tune in wherever you get podcasts</p>
                            </div>
                            <div class="columns is-compact is-mobile is-multiline has-margin-top-1">

                            </div>
                            <div id="show-tab-picker" class="tabs is-toggle">
                                <ul>

                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="container">
        <div class="columns is-multiline">
            <div class="column is-full">
                <article class="has-background-white-ter has-padding-4">
                    <h3 class="ui-header is-size-6 has-margin-bottom-7">
                        <a>
                            About Big Facts No Cap
                        </a>
                    </h3>
                    <p>
                        Big Facts, No Cap is an iPod broadcast in which two normal dudes with highly overlapping
                        worldviews give advice to online strangers who will probably never hear it. Using questions
                        from advice columns ranging in scope from dating to pet care to religion and everything in
                        between, Paul and Adrian deliver the best suggestions they can muster off the top of their
                        lovely
                        yet largely vacant heads. The duo’s tough love and hot takes also extend to the advice giver as
                        they critique the published response to each query from the column’s resident expert. Truly a
                        show that no one asked for, Big Facts No Cap is an unapologetic vanity project from two
                        (basically) adult men whose time would probably be better spent fixing their own problems. But
                        hey,
                        that sounds way less fun to listen to, doesn’t it?
                    </p>
                </article>
            </div>

            <div class="column is-half">
                <article class="has-background-white-ter has-padding-4">
                    <form>

                        <h3 class="ui-header is-size-6 has-margin-bottom-7">Newsletter</h3>
                        <p>Join our newsletter to stay up to date on our latest releases.</p>
                        <input type="email" id="email" name="email" [formControl]="email" >
                        <button  [disabled]="!email.valid" (click)="emailSubmit()">Submit</button>


                    </form>
                    <div *ngIf="emailMessage"><b>{{emailMessage}}</b></div>
                </article>
            </div>
            <div class=" column is-half">
                <article class="has-background-white-ter has-padding-4">
                    <h3 class="ui-header is-size-6 has-margin-bottom-7">Listen Here</h3>
                    <div class="has-margin-top-7">
                        <div class="columns is-mobile is-multiline">
                            <div class="column is-narrow">

                                <a class="icon image " href="https://podcasts.apple.com/us/podcast/big-facts-no-cap/id1520451082">
                                    <img class="png-logo" src="../../assets/logos/itunes.png">
                                </a>
                                <a class="icon image " href="https://podcasts.google.com/?feed=aHR0cHM6Ly9iaWdmYWN0c25vY2FwLmNvL3Jzcy9mZWVkLnhtbA&ep=14">
                                    <img class="png-logo" src="../../assets/logos/gcasts.png">
                                </a>

                                <a class="icon image " href="https://pca.st/5z3fhjb2">
                                    <img class="png-logo" src="../../assets/logos/pcasts.png">
                                </a>
                                <a class="icon image " href="https://www.stitcher.com/podcast/big-facts-no-cap">
                                    <img class="png-logo" src="../../assets/logos/Stitcher.png">
                                </a>
                                <a class="icon image " href="https://open.spotify.com/show/0zqMp24GYk2j0Nv4kSC9Gx?si=hZIPyMQeR3KfqRGcixNtXA">
                                    <img class="png-logo" src="../../assets/logos/spotify.png">
                                </a>
                                <a class="icon image " href="https://www.youtube.com/channel/UCUrj4KJ1pJRNmQmM3R04ykQ">
                                    <img class="png-logo-wide" src="../../assets/logos/youtube.png">
                                </a>
                            </div>

                        </div>
                    </div>
                    <a  routerLink="/episodes">Or listen to the episodes on this site </a>
                </article>
            </div>
        </div>
    </div>


</div>