import { Component, OnInit } from '@angular/core';
import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  public expanded = false;
  title = 'BIGFACTSNOCAP';
  ngOnInit(){
    this.expanded = false
    var firebaseConfig = {
      apiKey: "AIzaSyC7J9PXB7SNZgfgVmqU4r53rQHfpXmbzkc",
      authDomain: "big-facts-no-cap.firebaseapp.com",
      databaseURL: "https://big-facts-no-cap.firebaseio.com",
      projectId: "big-facts-no-cap",
      storageBucket: "big-facts-no-cap.appspot.com",
      messagingSenderId: "317915983672",
      appId: "1:317915983672:web:01c7b0e0cbbaece2161bcd",
      measurementId: "G-LYK2YGF8DP"
    };
    firebase.initializeApp(firebaseConfig);
  }
  expand(){
    this.expanded = !this.expanded;
  }
}
