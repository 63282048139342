<div class="container" class="overlay" *ngIf="loading">

    <div class="spinner-wrapper">
        <div class="row">
            <div id="loader">
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="dot"></div>
                <div class="loading"></div>
            </div>
        </div>
    </div>
  </div>
  <section *ngIf="!loading">
  <section #host class="hero show-hero is-black has-hero-image is-thin" >
    <div class="hero-body overlay-hover">
        <div class="container">
            <div class="columns">
  
                <div class="column description-section">
                    <div class="hero-content">
                        <h1 *ngIf="episodeIdFromRoute" class="hero-title has-margin-bottom-6">EPISODE {{episodeIdFromRoute}}</h1>
                        <h1 class="hero-title has-margin-bottom-6">{{episode.title['#cdata']}}<title>{{episode.title['#cdata']}}</title></h1>
  
                        <div class="columns is-compact is-mobile is-multiline has-margin-top-1">
  
                        </div>
                        <div id="show-tab-picker" class="tabs is-toggle">
                            <ul>
  
                            </ul>
                        </div>
  
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
    <div class="container pointer" >
      <div class="columns is-multiline">
          <div class="column is-full">
              <article class="has-background-white-ter has-padding-4">
                  <h3 class="ui-header is-size-6 has-margin-bottom-7">
                      <a>
                        {{episode.title["#cdata"]}}
                      </a>
                  </h3>
                  <p [innerHTML]="episode.description['#cdata']">
                  </p>
                  <br>
                  <audio id="player" controls>
                    <source id="player-source" [src]="episode.enclosure['@url']" type="audio/mpeg">
                  Your browser does not support the audio element.
                  </audio>
              </article>
          </div>
  
          
      </div>
  </div>
</section>