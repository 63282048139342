import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';

import { DetailsComponent } from './details/details.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import {AngularFireFunctionsModule} from '@angular/fire/functions'



import { ToastsContainer } from './services/toasts/toasts-container.component';
import { ToastService } from './services/toasts/toast-service';
import { PodcastComponent } from './episodes/podcast.component';
import { PodcastService } from './services/podcast.service';
import { HttpClientModule } from '@angular/common/http';
import { EpisodeDetailsComponent } from './episode-details/episode-details.component';
var firebaseConfig = {
  apiKey: "AIzaSyC7J9PXB7SNZgfgVmqU4r53rQHfpXmbzkc",
  authDomain: "big-facts-no-cap.firebaseapp.com",
  databaseURL: "https://big-facts-no-cap.firebaseio.com",
  projectId: "big-facts-no-cap",
  storageBucket: "big-facts-no-cap.appspot.com",
  messagingSenderId: "317915983672",
  appId: "1:317915983672:web:01c7b0e0cbbaece2161bcd",
  measurementId: "G-LYK2YGF8DP"
};
@NgModule({
  declarations: [
    AppComponent,
    DetailsComponent,
    ToastsContainer,
    PodcastComponent,
    EpisodeDetailsComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,  
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage,
    AngularFireFunctionsModule,
    
    ],
  providers: [ToastService, PodcastService],
  bootstrap: [AppComponent]
})
export class AppModule { }
