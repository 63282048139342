
import { PodcastFeed } from '../models/podcast-feed.model';
import { PodcastEpisode } from '../models/podcast-episode.model';

import { PodcastService } from '../services/podcast.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-podcast',
  templateUrl: './episode-details.component.html',
  styleUrls: ['./episode-details.component.scss']
})
export class EpisodeDetailsComponent implements OnInit {
  feed: PodcastFeed;
  public loading:boolean = false;
  public episode: any;
  public episodeIdFromRoute: number;
  public episodeIdStringFromRoute: string;

constructor(private route: ActivatedRoute, private podcastService: PodcastService,private titleService: Title, private metaService: Meta) { }
ngOnInit() {
  this.loading = true;
  const routeParams = this.route.snapshot.paramMap;
  this.episodeIdFromRoute = Number(routeParams.get('episode'));
  this.episodeIdStringFromRoute = String(routeParams.get('episode'));


    this.route.queryParamMap.subscribe(params => {
      this.podcastService.getPodcast().subscribe( feed => {
        this.loading=false;
        this.feed = feed;
        
        this.episode = this.feed.items.filter(episode => episode['itunes:episode'] == this.episodeIdFromRoute)[0]
        if (!this.episode){
          this.episode = this.feed.items.filter(episode => episode.title['#cdata'] == this.episodeIdStringFromRoute)[0]
        }
        this.loading = false;
        this.titleService.setTitle(this.episode.title['#cdata']);
        this.metaService.addTags([
          {name: 'keywords', content: this.episode.title['#cdata']},
          {name: 'description', content: this.episode.description['#cdata']},
          {name: 'robots', content: 'index, follow'}
        ]);
    
      }); 
    });
  }

}