<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>


<!-- Toolbar -->
<div class="toolbar" role="banner">

    <div id="size-burst">
        <div id="parallelogram" class="purp pointer-mobile" (click)="expand()">
            <div class="title span-burst">BIG FACTS NO CAP
                <span  class="mobile pointer bars" [ngClass]="{'change':expanded}">
                    <div class="bar1 bar" ></div>
                    <div class="bar2 bar"></div>
                    <div class="bar3 bar"></div>
                </span>

            </div>
        </div>
    </div>
    <div id="size-burst"  routerLink="/" [ngClass]="{'submenu-off':!expanded}">
        <div id="parallelogram" class="pointer green" routerLinkActive="selected_menu" [routerLinkActiveOptions]="{exact:true}">
            <div class="span-burst">HOME</div>
        </div>
    </div>

    <div id="size-burst"  routerLink="/episodes" [ngClass]="{'submenu-off':!expanded}">
        <div id="parallelogram" class="pointer blue" routerLinkActive="selected_menu" >
            <div class="span-burst">EPISODES</div>
        </div>
    </div>
</div>
<div class="stripe"></div>
<div class="content" role="main">


</div>

<router-outlet>

</router-outlet>