import { PodcastFeed } from '../models/podcast-feed.model';
import { PodcastEpisode } from '../models/podcast-episode.model';

import { PodcastService } from '../services/podcast.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.scss']
})
export class PodcastComponent implements OnInit {
  feed: PodcastFeed;
  public loading:boolean = false;

constructor(private route: ActivatedRoute, private podcastService: PodcastService) { }
ngOnInit() {
  
    this.route.queryParamMap.subscribe(params => {
      this.podcastService.getPodcast().subscribe( feed => {
        this.loading=false;
        this.feed = feed;
        this.feed?.items.forEach((item)=>{
          if (!item['itunes:episode']){
            item['itunes:episode']=item.title['#cdata'] 
          }
        })
      });
    });
  }

}