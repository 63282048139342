import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetailsComponent } from './details/details.component';
import { PodcastComponent } from './episodes/podcast.component';
import { EpisodeDetailsComponent } from './episode-details/episode-details.component';


const routes: Routes = [
  { path: '', component: DetailsComponent },
  { path: 'episodes', component: PodcastComponent },
  { path: 'episodes/:episode', component: EpisodeDetailsComponent },
  { path: '*',   component: DetailsComponent},
  { path: '**',   component: DetailsComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
