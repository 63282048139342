import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as firebase from 'firebase';
import { AngularFireFunctions } from '@angular/fire/functions/functions';
import { ToastService } from '../services/toasts/toast-service';
@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
  constructor(public toastService:ToastService) { }
  public email = new FormControl('', [
    Validators.required,
    Validators.email
  ])
  public emailMessage = null;
  public loading:boolean = false;
  @ViewChild('host') host: ElementRef;
  ngOnInit(): void {

    this.loading=false;
    // const emailReg = new RegExp(`(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\])`)
    // this.email.valueChanges.subscribe(value=>{
    //   if (value != null && emailReg.test(value)){
    //     this.validEmail = true;
    //   }
    //   else{
    //     this.validEmail = false;
    //   }
    // })
    // var firebaseConfig = {
    //   apiKey: "AIzaSyC7J9PXB7SNZgfgVmqU4r53rQHfpXmbzkc",
    //   authDomain: "big-facts-no-cap.firebaseapp.com",
    //   databaseURL: "https://big-facts-no-cap.firebaseio.com",
    //   projectId: "big-facts-no-cap",
    //   storageBucket: "big-facts-no-cap.appspot.com",
    //   messagingSenderId: "317915983672",
    //   appId: "1:317915983672:web:01c7b0e0cbbaece2161bcd",
    //   measurementId: "G-LYK2YGF8DP"
    // };
    // firebase.initializeApp(firebaseConfig);
    window.addEventListener('scroll', this.scroll, true);



  }
  
  emailSubmit() {
    this.loading=true;
    var writeListener = firebase.functions().httpsCallable('writeListener');
    writeListener({ email: this.email.value }).then( (result) =>{
      this.loading=false;
      // Read result of the Cloud Function.
      if(result.data=="Success"){
        this.toastService.show('Email Received!', { classname: 'bg-success text-light', delay: 10000 });
        this.emailMessage = "We received your email!";
      }else if(result.data=="Exists") {
        this.toastService.show('Email Not Received, Please try again later', { classname: 'bg-fail text-light', delay: 10000 });
        this.emailMessage = "This email is already registered, do you want us to send you 2 emails?";
      }else {
        this.toastService.show('Email Not Received, Please try again later', { classname: 'bg-fail text-light', delay: 10000 });
        this.emailMessage = "Something went wrong :(";

      };
 
    });
  }
  
  scroll = (event): void => {
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
    this.host.nativeElement.classList.add('animate');
  };
}
