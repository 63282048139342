<div class="container" class="overlay" *ngIf="loading">

  <div class="spinner-wrapper">
      <div class="row">
          <div id="loader">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="loading"></div>
          </div>
      </div>
  </div>
</div>
<section #host class="hero show-hero is-black has-hero-image is-thin">
  <div class="hero-body overlay-hover">
      <div class="container">
          <div class="columns">

              <div class="column description-section">
                  <div class="hero-content">
                      <h1 class="hero-title has-margin-bottom-6">EPISODES </h1>

                      <div class="columns is-compact is-mobile is-multiline has-margin-top-1">

                      </div>
                      <div id="show-tab-picker" class="tabs is-toggle">
                          <ul>

                          </ul>
                      </div>

                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
  <div class="container pointer" *ngFor="let item of this.feed?.items" [routerLink]="['/episodes', item['itunes:episode']]">
    <div class="columns is-multiline">
        <div class="column is-full">
            <article class="has-background-white-ter has-padding-4">
                <h3 class="ui-header is-size-6 has-margin-bottom-7">
                    <a>
                      {{item.title["#cdata"]}} 
                    </a> <a href="" style="font-size:.6em !important;bottom:10px;position:relative" [routerLink]="['/episodes', item['itunes:episode']]">more</a>
                </h3>
                <p [innerHTML]="item.description['#cdata']">
                </p>
                <br>
                <audio id="player" controls preload="none">
                  <source id="player-source" [src]="item.enclosure['@url']" type="audio/mpeg">
                Your browser does not support the audio element.
                </audio>
            </article>
        </div>

        
    </div>
</div>