import { PodcastEpisode } from './podcast-episode.model';
export class PodcastFeed {
  feed: {
    "itunes:author": string;
    description: string;
    image: string;
    link: string;
    title: string;
    url: string;
  };
items: PodcastEpisode[];
constructor( response ) {
    this.feed = response.rss.channel;
    this.items = response.rss.channel.item;
  }
}